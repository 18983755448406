import { ActionFunctionArgs, json } from "@remix-run/node";
import { Form, useActionData, useFetcher, useLocation, useNavigation, useSubmit } from "@remix-run/react";
import { Field, Fieldset, Legend, Submit, getProvinces } from "forms";
import { useEffect, useRef, useState } from "react";
import { ZodError, z } from "zod";
import { db } from "~/utils/drizzle.server";
import validator from 'validator'
import { CodeBlock, render } from "@react-email/components";
import { Email_MagicAuthentication } from "~/emails/magicAuthentication";
import { add, addMinutes, isAfter } from "date-fns";
import { sendEmail } from "~/utils/email.server";
import { env } from "~/utils/env.server";
import { rebates_consumers } from "database";
import { sendSMS } from "~/utils/twilio.server";
import { Email_EmailVerification } from "~/emails/emailVerification";
import { H1, H2 } from "ui";
import visaCard from '~/assets/visaCard.svg'
import VisaCard from "~/components/VisaCard";
import { useInterval } from "~/hooks/interval";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useLocale } from "remix-i18next/react";
import { useLocalization } from "~/hooks/localize";
import { generateCode } from "~/utils/auth.server";
import { signJwt } from "~/utils/jwt.server";
import * as argon2 from "argon2";
import { createId } from "@paralleldrive/cuid2";
import { eq } from "drizzle-orm";
import { createUserSession } from "~/utils/sessions.server";
import Syntax from "~/components/syntax";
import { t } from "~/utils/locales";

// Block revalidation - no loader data is changing and would be a wasted request
export function shouldRevalidate() {
  return false;
}

const ACTIONS = {
  LOOKUP: 'LOOKUP',
  COMPLETE_PROFILE: 'COMPLETE_PROFILE',
  SECURITY_CODE: 'SECURITY_CODE',
  POLL: 'POLL'
} as const


export async function action({ request }: ActionFunctionArgs) {
  const Parser = z.discriminatedUnion('_action', [
    z.object({
      _action: z.literal(ACTIONS.LOOKUP),
      username: z.string().transform((val) => val.toLowerCase()),
      timezone: z.string()
    }),
    z.object({
      _action: z.literal(ACTIONS.COMPLETE_PROFILE),
      firstName: z.string().min(2, { message: await t('error_firstName', request) }),
      lastName: z.string().min(2, { message: await t('error_lastName', request) }),
      email: z.string().email({ message: await t('error_email', request) }).transform(val => val.toLowerCase().trim()),
      phone: z.string().refine(val => validator.isMobilePhone(val, 'en-US'), { message: await t('error_phone', request) }).transform(val => val.replace(/\D/g, '').trim()),
      address: z.string().min(3, { message: await t('error_address', request) }),
      addressLine2: z.string().optional(),
      city: z.string().min(2, { message: await t('error_city', request) }),
      province: z.string().length(2, { message: await t('error_province', request) }),
      postal: z.string().refine(val => validator.isPostalCode(val, 'CA'), { message: await t('error_postal', request) }),
      username: z.string(),
      usernameType: z.string(),
      timezone: z.string()
    }),
    z.object({
      _action: z.literal(ACTIONS.SECURITY_CODE),
      code: z.string(),
      hId: z.string(),
      username: z.string()
    }),
    z.object({
      _action: z.literal(ACTIONS.POLL),
      hId: z.string(),
      code: z.string(),
      username: z.string()
    })
  ])
  const defaultReturn = {
    hid: null,
    code: null,
    username: null,
    error: null,
    usernameType: null,
    requestId: createId(),
    errors: null
  };
  const formData = await request.formData()
  const parserResult = await Parser.safeParseAsync(Object.fromEntries(formData))

  if (!parserResult.success) {
    return json({
      ...defaultReturn,
      state: formData.get('_action'),
      errors: parserResult.error,
      ...Object.fromEntries(formData)
    })
  }

  const { data } = parserResult

  if (data._action === ACTIONS.COMPLETE_PROFILE) {


    const [phoneExists, emailExists] = await Promise.all([
      db.query.rebates_consumers.findFirst({
        where: (rebates_consumers, { eq, and }) => and(eq(rebates_consumers.phone, data.phone), eq(rebates_consumers.isActive, true))
      }),
      db.query.rebates_consumers.findFirst({
        where: (rebates_consumers, { eq, and }) => and(eq(rebates_consumers.email, data.email), eq(rebates_consumers.isActive, true))
      })
    ])

    if (phoneExists) {

      const error = new ZodError([
        {
          code: 'custom',
          path: ['phone'],
          message: await t('error_phoneInUse', request),
        }
      ])

      return {
        ...defaultReturn,
        // error: 'USER_EXISTS_PHONE'
        errors: error,
        state: data._action
      }
    }
    if (emailExists) {
      const error = new ZodError([
        {
          code: 'custom',
          path: ['email'],
          message: await t('error_emailInUse', request)
        }
      ])
      return {
        ...defaultReturn,
        // error: 'USER_EXISTS_EMAIL'
        errors: error,
        state: data._action
      }
    }

    await db.insert(rebates_consumers).values({
      firstName: data.firstName,
      lastName: data.lastName,
      // businessName: '',
      email: data.email,
      phone: data.phone,
      phoneCountryCode: '+1',
      address: data.address,
      addressLine2: data.addressLine2,
      city: data.city,
      province: data.province,
      postal: data.postal,
      country: data.country,
      locale: 'en_CA',
      userType: 'CONSUMER',
      isTesting: Boolean(env.NODE_ENV === 'development'),

    })

    data._action = ACTIONS.LOOKUP

  }

  if (data._action === ACTIONS.SECURITY_CODE) {

    const { hId, code } = data

    const user = await db.query.rebates_consumers.findFirst({
      where: (rebates_consumers, { eq, and }) => and(eq(rebates_consumers.hId, hId), eq(rebates_consumers.isActive, true)),
    });

    if (!user)
      return {
        ...defaultReturn,
        error: "USER_NOT_FOUND",
      };

    if (
      !user.loginCodeExpires ||
      isAfter(new Date(), user.loginCodeExpires)
    ) {
      return {
        ...defaultReturn,
        error: "LOGIN_CODE_EXPIRED",
      };
    }

    const isValidated = user.loginCodeHash
      ? await argon2.verify(String(user.loginCodeHash), code)
      : false;

    if (isValidated) {
      await db
        .update(rebates_consumers)
        .set({
          loginId: null,
          loginCodeHash: null,
          loginCodeHashConfirmed: null,
          loginCodeExpires: null,
        })
        .where(eq(rebates_consumers.id, user.id));

      return createUserSession(
        request,
        '/',
        {
          id: user.id,
          uuid: String(user.uuid),
          hId: user.hId,
          userType: 'CONSUMER',
          lng: user?.locale ? user.locale.substring(0, 2) : undefined,
          firstName: String(user.firstName),
          lastName: String(user.lastName),
        }
      );
    }


    return {
      ...defaultReturn,
      state: ACTIONS.SECURITY_CODE,
      ...data,
      error: 'INVALID_CODE'
    }

  }

  if (data._action === ACTIONS.POLL) {
    const { hId, code } = data;
    const user = await db.query.rebates_consumers.findFirst({
      where: (rebates_consumers, { eq }) => eq(rebates_consumers.hId, hId),
    });

    if (!user)
      return {
        ...defaultReturn,
        error: "USER_NOT_FOUND",
      };

    if (
      !user.loginCodeExpires ||
      isAfter(new Date(), user.loginCodeExpires)
    ) {
      return {
        ...defaultReturn,
        error: "LOGIN_CODE_EXPIRED",
      };
    }

    const isValidated = user.loginCodeHashConfirmed
      ? await argon2.verify(String(user.loginCodeHashConfirmed), code)
      : false;

    if (isValidated) {
      await db
        .update(rebates_consumers)
        .set({
          loginId: null,
          loginCodeHash: null,
          loginCodeHashConfirmed: null,
          loginCodeExpires: null,
        })
        .where(eq(rebates_consumers.id, user.id));

      return createUserSession(
        request,
        '/',
        {
          id: user.id,
          uuid: String(user.uuid),
          hId: user.hId,
          userType: 'CONSUMER',
          lng: user?.locale ? user.locale.substring(0, 2) : undefined,
          firstName: String(user.firstName),
          lastName: String(user.lastName),
        }
      );

    }

    return {
      ...defaultReturn,
      state: ACTIONS.SECURITY_CODE,
      ...data
    }
  }

  if (data._action === ACTIONS.LOOKUP) {

    const { username } = data

    let usernameType: 'EMAIL' | 'PHONE' = 'EMAIL'
    let phone: string | null = null
    let email: string | null = null
    let user: undefined | typeof rebates_consumers.$inferSelect
    let isValidated = false
    if (validator.isMobilePhone(username, 'en-US')) {
      phone = username.replace(/\D/g, '').trim()
      usernameType = 'PHONE'
      user = await db.query.rebates_consumers.findFirst({
        where: (rebates_consumers, { eq, and }) => and(eq(rebates_consumers.phone, username), eq(rebates_consumers.isActive, true))
      })
      isValidated = true
    }
    if (validator.isEmail(username)) {
      email = username.toLowerCase().trim()
      usernameType = 'EMAIL'
      user = await db.query.rebates_consumers.findFirst({
        where: (rebates_consumers, { eq, and }) => and(eq(rebates_consumers.email, username), eq(rebates_consumers.isActive, true))
      })
      isValidated = true
    }

    if (!isValidated) return { ...defaultReturn, error: 'INVALID_USERNAME', state: ACTIONS.LOOKUP }

    if (user && user.hId) {

      const securityCode = generateCode()
      const hash = await argon2.hash(securityCode, { type: argon2.argon2id });

      const loginId = createId();
      await db
        .update(rebates_consumers)
        .set({ loginId, loginCodeHash: hash, loginCodeHashConfirmed: null, loginCodeExpires: addMinutes(new Date(), 15) })
        .where(eq(rebates_consumers.id, user.id));

      if (email) {

        const token = signJwt({ knd: 'LOGIN', sub: user.hId, jti: loginId, code: securityCode })

        const expiresAt = add(new Date(), { minutes: 15 })
        const html = await render(<Email_MagicAuthentication timezone={data.timezone} token={token} code={securityCode} expiresAt={expiresAt} />)
        await sendEmail({
          to: [env.NODE_ENV === 'development' ? 'dane@dsmedia.ca' : email],
          html,
          subject: 'Sign in to your account'
        })
      }

      if (phone) {
        await sendSMS({
          body: `Security code for NAPA Rebates: ${securityCode}\nCode de sécurité pour les rabais NAPA : ${securityCode}`,
          to: env.NODE_ENV === 'development' ? '5192163749' : phone
        })
        if (!user.isVerifiedEmail && user.email) {

          const token = signJwt({ knd: 'VERIFY_EMAIL', sub: user.hId })

          const html = await render(<Email_EmailVerification token={token} />)
          await sendEmail({
            to: [env.NODE_ENV === 'development' ? 'dane@dsmedia.ca' : user.email],
            html,
            subject: 'Please verify your email address'
          })
        }
      }

      return {
        ...defaultReturn,
        state: ACTIONS.SECURITY_CODE,
        hId: user.hId,
        code: securityCode,
        ...data,
      }

    }
    return {
      ...defaultReturn,
      state: ACTIONS.COMPLETE_PROFILE,
      usernameType,
      username
    }
  }



  return { ...defaultReturn }


}

export default function Login() {

  const { state, username, usernameType, hId, code, error, errors } = useActionData<typeof action>() || { state: ACTIONS.LOOKUP, username: null, errors: {} }

  const locale = useLocale()
  const { t, language } = useLocalization()
  const fetcher = useFetcher()
  const location = useLocation()
  const navigation = useNavigation()

  const pollingFormRef = useRef<HTMLFormElement>(null);
  const submit = useSubmit();

  useInterval(
    () => {
      if (state === ACTIONS.SECURITY_CODE) {
        if (navigation.state === 'idle') {
          submit(pollingFormRef.current);
        }
      }
    },
    state === ACTIONS.SECURITY_CODE ? 5000 : 0,
  );

  console.log(errors)

  const ERRORS: Record<string, string> = {
    USER_NOT_FOUND: t('thisTokenHasExpired'),
    INVALID_TOKEN: t('invalidJwtToken'),
    INVALID_CODE: t('incorrectSecurityCodePleaseTryAgain'),
    LOGIN_CODE_EXPIRED: t('thisTokenHasExpired'),
    INVALID_JWT: t('thisTokenIsInvalid'),
  };

  if (state === ACTIONS.SECURITY_CODE) {
    return (
      <div className='text-center'>
        <H1>{t('weSentASecurityCode')}<br /><strong>{username}</strong></H1>
        <CodeField hId={hId} username={username} />
        {error && (
          <div>
            <div className="flex justify-center">
              <div className=" text-center mt-4 text-red-600 dark:text-red-400 bg-red-100 border border-red-200 dark:border-red-900 dark:bg-red-900/10 rounded px-4 py-2 text-sm">
                {ERRORS[error] || 'Unknown error'}
              </div>
            </div>
          </div>
        )}
        <Form ref={pollingFormRef} method="POST">
          <input type="hidden" name="_action" value={ACTIONS.POLL} />
          <input type="hidden" name="hId" value={hId} />
          <input type="hidden" name="code" value={code} />
          <input type="hidden" name="username" value={username} />
        </Form>
      </div>
    )
  }

  if (state === ACTIONS.COMPLETE_PROFILE) {
    return (
      <div>
        <Form method="POST">
          <input type="hidden" name="_action" value={ACTIONS.COMPLETE_PROFILE} />
          <input type="hidden" name="username" value={username} />
          <input type="hidden" name="usernameType" value={usernameType} />
          <input type="hidden" name="timezone" value={Intl.DateTimeFormat().resolvedOptions().timeZone} />
          <Fieldset>
            <Legend>{t('completeYourProfile')}</Legend>
            <Field name="firstName" label={t('firstName')} errors={errors} />
            <Field name="lastName" label={t('lastName')} errors={errors} />
            <Field name="email" type="email" label={t('email')} defaultValue={usernameType === 'EMAIL' ? username : ''} disabled={Boolean(usernameType === 'EMAIL')} errors={errors} />
            <Field name="phone" type="tel" label={t('phone')} defaultValue={usernameType === 'PHONE' ? username : ''} disabled={Boolean(usernameType === 'PHONE')} errors={errors} />
          </Fieldset>
          <Fieldset>
            <Legend>{t('location')}</Legend>
            <Field name="address" label={t('address')} errors={errors} />
            <Field name="addressLine2" label={t('addressLine2')} errors={errors} />
            <Field name="city" label={t('city')} errors={errors} />
            <Field name="province" label={t('province')} errors={errors} type='select' options={getProvinces(language)} />
            <Field name="postal" label={t('postal')} helper="A1A 1A1" errors={errors} />
          </Fieldset>
          <Submit>{t('next')}</Submit>
        </Form>
      </div>

    )
  }


  return (
    <div>
      <img className="w-[340px] mx-auto mb-12" alt="Image of visa card" src={visaCard} />
      <Form method="POST">
        <input type="hidden" name="_action" value={ACTIONS.LOOKUP} />
        <input type="hidden" name="timezone" value={Intl.DateTimeFormat().resolvedOptions().timeZone} />
        <div className="text-center">
          <h1 className="text-xl text-primary mb-4">{t('submitOrTrackYourRebate')}</h1>
          <Field name="username" label={t('emailOrCellPhone')} error={Boolean(error === 'INVALID_USERNAME')} errorMessage="Please enter a valid email or cell phone." />
          <Submit>{t("next")}</Submit>
        </div>

        <h2 className="mt-16 mb-4 text-primary text-lg">{t('beforeYouBegin')}</h2>
        <ul className="list-disc ml-8 my-4">
          <li>{t('originalServiceInvoiceCopy')}</li>
          <li>{t('promoCodeFromYourRebateClaimForm')}</li>
        </ul>

      </Form>
      <fetcher.Form
        method="post"
        action={`/changeLanguage?redirectTo=${location?.pathname}`}
      >
        <button
          type="submit"
          name="lng"
          value={locale === "fr" ? "en" : "fr"}
          className="flex gap-2 items-center  mt-16 mx-auto text-primary bg-zinc-100 px-4 py-2 rounded hover:bg-zinc-200 hover:text-primaryDark"
        >
          {" "}
          <Icon icon="mdi:translate" fontSize={24} />
          {t("lng")}
        </button>
      </fetcher.Form>
    </div>
  )
}
function CodeField({ hId, username }: { hId: string; username: string; }) {
  const { error, requestId } = useActionData<typeof action>() || { error: null }
  const [isFocused, setIsFocused] = useState(false);
  const [code, setCode] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [loadingCount, setLoadingCount] = useState(0)
  const submit = useSubmit();
  useEffect(() => {
    inputRef?.current?.focus();
  }, []);
  useEffect(() => {
    if (code.length === 6) {
      submit(formRef.current);
    }
  }, [submit, code]);

  useInterval(() => {
    setLoadingCount(loadingCount === 5 ? 0 : loadingCount + 1)
  }, code.length === 6 ? 250 : 0)

  useEffect(() => {


    console.log('ERROR----', error)
    if (error === 'INVALID_CODE') {

      setCode("")
    }

  }, [error, requestId])
  const codeBlocks = [0, 1, 2, 3, 4, 5]

  return (
    <Form method="POST" ref={formRef}>
      <input type="hidden" name="hId" value={hId} />
      <input type="hidden" name="username" value={username} />
      <input type="hidden" name="_action" value={ACTIONS.SECURITY_CODE} />
      <label>
        <span className="hidden">code</span>
        <input
          ref={inputRef}
          type="number"
          name="code"
          value={code}
          className="w-0 h-0 bg-transparent"
          onChange={(e) => setCode(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          maxLength={6}
        />
        <div className="flex items-center justify-center gap-2">
          {
            codeBlocks?.map((i) => {
              return (
                <CodePart key={i} i={i} isFocused={isFocused} code={code} loadingCount={loadingCount} />
              )
            })
          }
        </div>
      </label>
    </Form>
  );
}

function CodePart({ i, isFocused, code, loadingCount }: { i: number; isFocused: boolean; code: string; loadingCount: number; }) {
  const codeParts = code.split('')
  const isActive = isFocused && code.length === i
  const isEntered = code.length === 6
  return (
    <div
      className={`
        size-12 border rounded flex items-center justify-center text-3xl
        ${isActive ? `ring ring-blue-400` : ""}
        ${isEntered && loadingCount === i ? 'border-primary' : ''}
      `}
    >
      {codeParts[i] || ""}
      <div className={`
        ${isActive ? '' : 'hidden'}
        w-[1px]
        h-3/5
        bg-primary
        animate-blink
      `} />
    </div>
  )
}
